import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import ContactForm from "../components/contactForm"

export const query = graphql`
  query($Slug: String) {
    sanityServices(slug: { current: { eq: $Slug } }) {
      seo {
        seo_title
        meta_description
      }
      title
      subtitle
      _rawBody
      templateList
      mainImage {
        alt
        image {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    allSanityServices(sort: { fields: publishedAt, order: ASC }) {
      edges {
        node {
          title
          subtitle
          slug {
            current
          }
        }
      }
    }
  }
`

const Service = props => {
  return (
    <Layout>
      {props.data.sanityServices.seo && (
        <SEO
          title={props.data.sanityServices.seo.seo_title}
          metaDescription={props.data.sanityServices.seo.meta_description}
          seoTitle={props.data.sanityServices.seo.seo_title}
        />
      )}
      <div className="sub_header">
        {props.data.allSanityServices.edges.map(function(service) {
          return (
            <Link
              activeClassName="active"
              to={`/${service.node.slug.current}/`}
            >
              {service.node.title}
            </Link>
          )
        })}
      </div>
      <section className="services">
        <div className="upper_wrapper">
          <Img
            fluid={props.data.sanityServices.mainImage.image.asset.fluid}
            alt={props.data.sanityServices.mainImage.alt}
            className="addMargin"
          ></Img>
          <div className="text_content">
            <h1>{props.data.sanityServices.title}</h1>
            <p>{props.data.sanityServices.subtitle}</p>
          </div>
        </div>
        <div>
          <BlockContent
            className={props.data.sanityServices.templateList}
            blocks={props.data.sanityServices._rawBody}
            projectId="ppd47c0u"
            dataset="production"
            serializers={serializers}
          />
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default Service
